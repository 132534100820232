import Mention from "@tiptap/extension-mention";
import { SolidNodeViewRenderer, SolidRenderer, getTiptapSolidReactiveOwner } from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "~/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { MentionNode, MentionsContainer, type MentionsProps } from "./Mentions.render";
import { insertCharacterCommand } from "../helpers";
import { AssetLifecycleStates, type assets, getRequestClient } from "@repo/client";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";

export type MentionItemType =
  | {
      type: "asset";
      associatedCollection: string;
      data: assets.AssetSnapshot;
    }
  | {
      type: "collection";
      associatedCollection: string;
      data: CollectionSnapshot;
    };
export type MentionsSuggestionProps = SuggestionProps<MentionItemType>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Mention: {
      invokeMention: () => ReturnType;
    };
  }
}

export const MentionsExtension = Mention.extend({
  name: "mentionNode",
  addAttributes() {
    return {
      type: "",
      associatedCollection: "",
      data: {},
    };
  },
  addNodeView() {
    return SolidNodeViewRenderer(MentionNode);
  },
  addCommands: () => ({
    invokeMention: () => insertCharacterCommand("@"),
  }),
}).configure({
  suggestion: {
    pluginKey: new PluginKey("mention"),
    char: "@",
    items: async ({ query, editor }): Promise<MentionItemType[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      if (!wire) return [];

      const client = getRequestClient(wire.services.identity.getIdentityToken);

      let assets: MentionItemType[] = [];
      try {
        if (query.length >= 3) {
          assets = await client.controlplane
            .SearchForAssets({
              matchName: query,
              pagination: {
                Limit: 6,
                Offset: 0,
                Sort: [],
              },
              lifecycleStates: [AssetLifecycleStates.Ready],
              filterByCollections: [],
              filterByContentType: [],
            })
            .then((r) =>
              r.data.result.entities.map(
                (a) => ({ type: "asset", data: a.data, associatedCollection: a.collectionIds?.[0] || "" }) as const,
              ),
            );
        } else {
          assets = await client.controlplane
            .RecentAssets({
              limit: 30,
              offset: 0,
              lifecycleStates: AssetLifecycleStates.Ready,
              filterByContentType: "",
              filterByAccessTypes: "",
            })
            .then((r) =>
              r.data.result.entities
                .map(
                  (a) => ({ type: "asset", data: a.data, associatedCollection: a.collectionIds?.[0] || "" }) as const,
                )
                .filter(
                  (a) =>
                    a.data.originalFilename.toLowerCase().includes(query) ||
                    a.data.displayName.toLowerCase().includes(query),
                )
                .slice(0, 6),
            );
        }
      } catch (error) {
        console.error(error);
      }

      const collections = wire.services.collections
        .getAllCollections()
        .filter(
          (c) =>
            c.label.replaceAll(" ", "").toLowerCase().includes(query) && c.collectionKind !== CollectionKind.Favorites,
        )
        .slice(0, 6)
        .map(
          (c) =>
            ({
              type: "collection",
              data: c,
              associatedCollection: "",
            }) as const,
        );

      return [...collections, ...assets];
    },
    render: () => {
      let solidRenderer: SolidRenderer<MentionsProps> | undefined;
      return {
        onStart: (props: MentionsSuggestionProps) => {
          solidRenderer = new SolidRenderer(MentionsContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);
        },
        onUpdate(props: MentionsSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
  renderText({ node, options }) {
    return `${options.suggestion.char}${node.attrs.id ?? node.attrs.name}`;
  },
});
